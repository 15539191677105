import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

function TableElement(props) {

    /*const renderTableCells = () => {
        let c = [];
        props.columns.map(item => {
            c.push(<TableCell>{item.label}</TableCell>);
        })
        return c;
    }

    const renderBodyTable = () => {
        let b = [];        
        props.rows.map((row, index) => (
            b.push(<TableRow key={index + "_" + props.id_table}>
                {props.columns.map(col => {
                    return (<TableCell component="th" scope="row">
                        {row[col.name]}
                    </TableCell>)
                })}
            </TableRow>)
        ));
        return b;
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {renderTableCells()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderBodyTable()}
                </TableBody>
            </Table>
        </TableContainer>
    );*/
    const renderDatagrid = () => {

        switch (props.id_table) {

            case 'contracts': return (
                <DataGrid
                    rows={props.rows}
                    columns={props.columns}
                    density={'compact'}
                    onRowDoubleClick={(GridRowParams, e, GridCallbackDetails) => {
                        props.handleDoubleClick(GridRowParams, e, GridCallbackDetails)
                    }}
                />
            );

            case 'schedules': return (
                <DataGrid
                    rows={props.rows}
                    columns={props.columns}
                    density={'compact'}
                    onRowDoubleClick={(GridRowParams, e, GridCallbackDetails) => {
                        props.handleDoubleClick(GridRowParams, e, GridCallbackDetails)
                    }}
                />
            )

            case 'playlist': return (
                <DataGrid
                    rows={props.rows}
                    columns={props.columns}
                    density={'compact'}
                /*onCellClick={(params, event, details) => props.checkPlayback(params, event, details)}*/
                />
            )

            default: return (
                <DataGrid
                    rows={props.rows}
                    columns={props.columns}
                    density={'compact'}
                />
            )


        }

    }

    return (
        <Box sx={{ height: 'calc(100% - 80px)' }}>
            {renderDatagrid()}
        </Box>
    )




}

export default TableElement;