import React from 'react';

function Loading() {

    return (

        <div className='app-dark-loading'>
            Loading
        </div>

    )

}

export default Loading;