import React, { useEffect, useState } from 'react';
import TableElement from './TableElement';
import Loading from './Loading';

import axios from 'axios';

function ContractList(props) {

    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);
        loadContracts();

    }, []);

    const loadContracts = () => {

        axios.get('api/app.php?getContracts').then(x => {

            setLoading(false);

            if (typeof x.data !== 'object') return false;
            setRows(x.data);

        }).catch(error => {


        });

    }

    const width_ = window.innerWidth;

    const [columns, setColumns] = useState([
        {
            field: 'ContractNr',
            headerName: 'Nr Contratto',
            width: (width_ / 100) * 10,
            type: "number",
        },
        {
            field: 'Cname',
            headerName: 'Cliente',
            width: (width_ / 100) * 15,
            type: "string",
        },
        {
            field: 'ExtCode',
            headerName: 'Cod. Esterno',
            width: (width_ / 100) * 10,
            type: "string",
        },
        {
            field: 'Piva',
            headerName: 'IVA',
            width: (width_ / 100) * 10,
            type: "number",
        },
        {
            field: 'DateStart',
            headerName: 'Inizio Periodo',
            width: (width_ / 100) * 7.5,
            type: "date",
        },
        {
            field: 'DateEnd',
            headerName: 'Fine Periodo',
            width: (width_ / 100) * 7.5,
            type: "date",
        },
        {
            field: 'Alias',
            headerName: 'Alias',
            width: (width_ / 100) * 15,
        },
        {
            field: 'Assigned',
            headerName: 'Assegnato',
            width: (width_ / 100) * (15 + 9),
        },
        /*{
            field: 'Tot_Auto',
            headerName: 'Totale',
            width: (width_ / 100) * 9,
            align: 'right',
            type: "number",
        },*/
    ]);

    const openSchedule = (params, e, GridCallbackDetails) => {

        //console.log(params)
        props.moveHistory('/schedules?idcontract=' + params.row.id);


    }

    const updateRows = () => {

        loadContracts();

    }

    return (

        <div className='app-tablecnt-wrapper'>
            {loading ? <Loading /> : ''}
            <div className='app-table-filter-cnt'>
                <div className='app-table-title'>Contratti</div>

                <div className='app-table-filter-item-cnt'>
                    <button className='app-refresh-table-btn' onClick={() => updateRows()} >Aggiorna Tabella</button>
                </div>

            </div>
            <TableElement
                columns={columns}
                id_table='contracts'
                rows={rows}
                handleDoubleClick={openSchedule}
            />
        </div>

    );



}

export default ContractList;