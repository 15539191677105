import React, { useState, useEffect } from 'react';

import {
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";

import TopBar from "./components/TopBar";
import ContractList from "./components/Tables/ContractList";
import ScheduleList from './components/Tables/ScheduleList';
import PlayList from './components/Tables/PlayList';
import Materials from './components/Tables/Materials';
import Login from './components/Login';
import axios from 'axios';



function App() {

  let history = useHistory();
  const [refresh, setRefresh] = useState(new Date());
  const [username, setUsername] = useState("");
  const [logged, setLogin] = useState(false);
  const [loading, setLoading] = useState(false);


  const [loginImage, setLoginImage] = useState("");
  const [loginCustomerImages, setLoginCustomerImages] = useState([]);
  const [topbarImage, setTopbarImage] = useState("");

  useEffect(() => {

    axios.get('api/app.php?login_image').then(x => {
      setLoginImage(x.data);
    });
    axios.get('api/app.php?login_radio_images').then(x => {
      setLoginCustomerImages(x.data);
    });
    axios.get('api/app.php?topbar_image').then(x => {
      setTopbarImage(x.data);
    });


    // devo verificare il login :)))
    setLoading(true);
    axios.get('api/app.php?session').then(x => {

      setLoading(false);
      if (x.data === 'session_ok') {
        setLogin(true);

        //moveHistory('/contracts');

        axios.get('api/app.php?info').then(x => {
          setUsername(x.data.username);
        }).catch(error => {

        });


      } else
        moveHistory('/login');

    }).catch(error => {


    })

  }, []);

  const handleLogout = () => {
    axios.post('api/app.php?logout').then(x => {
      window.location.reload();
    });
  }

  const moveHistory = (url) => {
    history.push(url);
    setRefresh(new Date());
  }

  return (
    <div className="app-main-cnt">
      {logged && !loading ? <TopBar moveHistory={moveHistory} handleLogout={handleLogout} username={username} topbarImage={topbarImage} /> : ''}
      <div className='switch-wrapper'>
        <Switch className="switch-wrapper">


          {logged && !loading ? <div>

            <Route
              exact
              path="/contracts"
              render={(props) => (
                <ContractList {...props} moveHistory={moveHistory} />
              )}
            />

            <Route
              exact
              path="/schedules"
              render={(props) => (
                <ScheduleList {...props} moveHistory={moveHistory} />
              )}
            />

            <Route
              exact
              path="/playlist"
              render={(props) => (
                <PlayList {...props} moveHistory={moveHistory} />
              )}
            />

            <Route
              exact
              path="/materials"
              render={(props) => (
                <Materials {...props} moveHistory={moveHistory} />
              )}
            />
          </div>
            :
            (<div>
              <Route
                exact
                path="/login"
                render={(props) => (
                  <Login {...props} loginImage={loginImage} customerImages={loginCustomerImages} />
                )}
              />
            </div>)}


          <Redirect to={'/contracts'} />

          {/*<ContractList />*/}
          {/*<ScheduleList />*/}
          {/*<PlayList />*/}
          {/*<Materials />*/}
        </Switch>
      </div>
    </div>
  );
}

export default App;
