import axios from 'axios';
import React, { useState, useEffect } from 'react';

function Login(props) {

    const [code, setCode] = useState("");
    const [password, setPwd] = useState("");
    const [errorLogin, setError] = useState(false);


    const handleLogin = (e) => {

        e.preventDefault()
        if (code === '' || password === '') return false;

        setError(false);

        let formdata = new FormData();
        formdata.append('code', code);
        formdata.append('password', password);

        axios.post('api/app.php?login', formdata).then(x => {

            if (x.data === 'login_ok') {
                //login.
                window.location.reload();
            } else {
                // errore login.
                setError(true);
            }

        }).catch(error => {



        });

    }

    return (

        <div className='app-login-absolute-cnt'>

            <div className='app-login-cnt-left'>

                <div className='app-login-logospot' style={{ backgroundImage: 'url("' + props.loginImage + '")' }} />
                {/*<div className='app-login-logospot-customercnt'>
                    {props.customerImages.map((base64, index) => {

                        return <div key={'img' + index} className='app-login-customerimage' style={{ backgroundImage: 'url("' + base64 + '")' }}></div>

                    })}
                </div>*/}

            </div>
            <div className='app-login-cnt-right'>
                <form className='app-login-right-form' onSubmit={(e) => handleLogin(e)}>
                    <input className='app-login-input' placeholder='Codice Cliente' value={code} onChange={(e) => { setCode(e.target.value); setError(false) }} />
                    {/*style={error ? { borderColor: 'red', color: 'red' } : {}}*/}
                    <input className='app-login-input' placeholder='Password' type='password' value={password} onChange={(e) => { setPwd(e.target.value); setError(false) }} />
                    {errorLogin ? <div className='app-login-errormessage'>Le tue credenziali sono errate, accesso negato.</div> : ''}
                    <input type='submit' className='app-login-button' value='Accedi' />
                </form>
            </div>

        </div>

    )

}

export default Login;