import React, { useEffect, useState } from 'react';
import TableElement from './TableElement';
import Loading from './Loading';

import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';

function ContractList(props) {

    const [rows, setRows] = useState([]);
    const [channels, setChannels] = useState([]);
    const [areas, setAreas] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedChannel, setSelectedChannel] = useState(-1);
    const [selectedArea, setSelectedArea] = useState(-1);

    let history = useHistory();
    let params = useParams();


    useEffect(() => {

        loadSchedules();

        axios.get('api/app.php?getChannels').then(x => {

            setChannels(x.data);

        }).catch(error => { });

        axios.get('api/app.php?getAreas').then(x => {

            setAreas(x.data);

        }).catch(error => { });


    }, []);

    const parseSearch = () => {

        if (typeof history.location.search !== 'undefined' && history.location.search !== '') {
            let min_quest = history.location.search.substr(1, history.location.search.length);
            if (min_quest.split("=")[0] === "idcontract") return min_quest.split("=")[1];
            return "";
        } else {

            return "";

        }

    }

    const loadSchedules = () => {

        const channelQ = selectedChannel === -1 || typeof channels[selectedChannel] === 'undefined' ? '' : ("&channel=" + channels[selectedChannel].ID);
        const areaQ = selectedArea === -1 || typeof areas[selectedArea] === 'undefined' ? '' : ("&area=" + areas[selectedArea].ID);

        const search = parseSearch();

        const contractsQ = typeof search !== 'undefined' && search !== "" ? "&idcontract=" + search.toString() : "";
        //const contractsQ = typeof history.match.params.contracts !== 'undefined' ? ("&idc=" + history.params.contracts) : '';

        setRows([]);
        setLoading(true);

        axios.get('api/app.php?getSchedules' + channelQ.toString() + areaQ.toString() + contractsQ.toString()).then(x => {

            setLoading(false);
            if (typeof x.data !== 'object') return false;
            setRows(x.data);

        }).catch(error => { });

    }

    useEffect(() => {

        setSelectedArea(-1);

    }, [selectedChannel])

    const width_ = window.innerWidth;

    const [columns, setColumns] = useState([
        {
            field: 'DateFrom',
            headerName: 'Inizio Periodo',
            width: (width_ / 100) * 10,
            type: "date",
        },
        {
            field: 'DateTo',
            headerName: 'Fine Periodo',
            width: (width_ / 100) * 10,
            type: "date",
        },
        {
            field: 'ContractNr',
            headerName: 'Nr. Contratto',
            width: (width_ / 100) * 10,
            type: "numeric",
        },
        {
            field: 'Cname',
            headerName: 'Cliente',
            width: (width_ / 100) * 15,
        },
        {
            field: 'ExtCode',
            headerName: 'Cod. Esterno',
            width: (width_ / 100) * 7.5,
            type: "numeric",
        },
        {
            field: 'Category',
            headerName: 'Categoria Prodotto',
            width: (width_ / 100) * 15,
        },
        {
            field: 'Product',
            headerName: 'Prodotto',
            width: (width_ / 100) * 10,
        },
        {
            field: 'Days',
            headerName: 'Giorni',
            width: (width_ / 100) * 10,
        },
        {
            field: 'AgentName',
            headerName: 'Agente',
            width: (width_ / 100) * 10,
        },
    ]);

    const renderChannels = () => {
        let c = [];
        c.push(<option value={-1}>- - -</option>);
        channels.map((item, index) => {
            c.push(<option value={index}>{item.Name}</option>);
        });
        return c;
    }

    const renderAreas = () => {
        let a = [];
        a.push(<option value={-1}>- - -</option>);
        areas.map((item, index) => {
            if (selectedChannel > -1 && channels[selectedChannel].ID !== item.IDChannel) return;
            a.push(<option value={index}>{item.Name}</option>);
        });
        return a;
    }

    const updateRows = () => {

        loadSchedules();

    }

    const openPlaylist = (params) => {

        props.moveHistory('/playlist?idschedule=' + params.row.id);

    }

    return (

        <div className='app-tablecnt-wrapper'>
            {loading ? <Loading /> : ''}
            <div className='app-table-filter-cnt'>
                <div className='app-table-title'>
                    Programmazioni
                    {parseSearch() !== '' ? <div className='app-table-subtitle'>ID Contratto {parseSearch()}</div> : ''}
                </div>
                <div className='app-table-filter-item-cnt'>
                    <div className='app-table-filter-label'>Canale</div>
                    <select value={selectedChannel} onChange={(e) => setSelectedChannel(e.target.value)} className='app-table-filter-input'>{renderChannels()}</select>
                </div>
                <div className='app-table-filter-item-cnt'>
                    <div className='app-table-filter-label'>Area</div>
                    <select value={selectedArea} onChange={(e) => setSelectedArea(e.target.value)} className='app-table-filter-input'>{renderAreas()}</select>
                </div>
                <div className='app-table-filter-item-cnt'>
                    <button className='app-refresh-table-btn' onClick={() => updateRows()} >Aggiorna Tabella</button>
                </div>
            </div>
            <TableElement columns={columns} id_table='schedules' rows={rows} handleDoubleClick={openPlaylist} />


        </div>

    );



}

export default ContractList;