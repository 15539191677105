import React, { useEffect, useState } from 'react';
import TableElement from './TableElement';
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import Loading from './Loading';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import { useHistory } from 'react-router-dom';

import axios from 'axios';

function ContractList() {

    let history = useHistory();

    const parseSearch = () => {

        if (typeof history.location.search !== 'undefined' && history.location.search !== '') {
            let min_quest = history.location.search.substr(1, history.location.search.length);
            if (min_quest.split("=")[0] === "idschedule") return min_quest.split("=")[1];
            return "";
        } else {
            return "";
        }

    }

    const srch = parseSearch();

    const [rows, setRows] = useState([]);
    const [channels, setChannels] = useState([]);
    const [areas, setAreas] = useState([]);
    const [date, setDate] = useState(srch === "" ? new Date() : new Date().setDate(new Date().getDate() - 15));
    const [dateTo, setDateTo] = useState(srch === "" ? new Date() : new Date().setDate(new Date().getDate() + 15));
    const [loading, setLoading] = useState(false);

    const [selectedChannel, setSelectedChannel] = useState(-1);
    const [selectedArea, setSelectedArea] = useState(-1);
    const [playingPath, setPlayPath] = useState("");
    const [mimeType, setMimeType] = useState("audio");

    useEffect(() => {

        loadSchedules();

    }, []);


    const loadSchedules = () => {

        const channelQ = selectedChannel === -1 || typeof channels[selectedChannel] === 'undefined' ? '' : ("&channel=" + channels[selectedChannel].ID);
        const areaQ = selectedArea === -1 || typeof areas[selectedArea] === 'undefined' ? '' : ("&area=" + areas[selectedArea].ID);

        const search = parseSearch();
        const scheduleQ = typeof search !== 'undefined' && search !== "" ? "&idschedule=" + search.toString() : "";

        setRows([]);


        setLoading(true);
        axios.get('api/app.php?getPlaylist&date=' + new Date(date).toLocaleDateString('it-IT').replace(/[/]/g, '-') + "&dateto=" + new Date(dateTo).toLocaleDateString('it-IT').replace(/[/]/g, '-') + channelQ.toString() + areaQ.toString() + scheduleQ.toString()).then(x => {

            setLoading(false);
            if (typeof x.data !== 'object') return false;
            setRows(x.data);


        }).catch(error => { });

        const from = "&date=" + new Date(date).toLocaleDateString('it-IT').replace(/[/]/g, '-');
        const to = "&dateto=" + new Date(dateTo).toLocaleDateString('it-IT').replace(/[/]/g, '-');


        axios.get('api/app.php?getChannels' + from + to).then(x => {

            setChannels(x.data);

        }).catch(error => { });

        axios.get('api/app.php?getAreas' + from + to).then(x => {

            setAreas(x.data);

        }).catch(error => { });

    }

    useEffect(() => {

        setSelectedArea(-1);

    }, [selectedChannel]);



    const width_ = window.innerWidth;

    const [columns, setColumns] = useState([
        {
            field: 'ChannelName',
            headerName: 'Canale',
            width: (width_ / 100) * 12,
        },
        {
            field: 'AreaName',
            headerName: 'Area',
            width: (width_ / 100) * 12,
        },
        {
            field: 'Cname',
            headerName: 'Cliente',
            width: (width_ / 100) * 10,
        },
        {
            field: 'Time',
            headerName: 'Orario',
            width: (width_ / 100) * 5,
            align: 'right',
            type: "time",
        },
        {
            field: 'Date1',
            headerName: 'Data',
            width: (width_ / 100) * 6,
            type: "time",
        },
        {
            field: 'Title',
            headerName: 'Titolo',
            width: (width_ / 100) * 20,
        },
        {
            field: 'Filename',
            headerName: '',
            width: (width_ / 100) * 2,
            renderCell: (params) => {
                if (params.row.filename !== '' && params.row.wasFound == 1)
                    return (
                        <div onClick={() => checkPlayback(params)} className="app-playback-material" style={{ cursor: "pointer" }}>
                            <div className='app-username-icon-cnt app-play-icon' />
                        </div>
                    ); else {
                    return <div />
                }
            }
        },
        {
            field: 'Product',
            headerName: 'Prodotto',
            width: (width_ / 100) * 8,
        },
        {
            field: 'Len',
            headerName: 'Durata',
            width: (width_ / 100) * 5,
            align: 'right',
            type: "number",
        },
        {
            field: 'Category',
            headerName: 'Categoria',
            width: (width_ / 100) * 10,
        },
        {
            field: 'ContractNr',
            headerName: 'Nr. Contratto',
            width: (width_ / 100) * 7.5,
            type: "string",
        },

    ]);

    const renderChannels = () => {
        let c = [];
        c.push(<option value={-1}>- - -</option>);
        channels.map((item, index) => {
            c.push(<option value={index}>{item.Name}</option>);
        });
        return c;
    }

    const renderAreas = () => {
        let a = [];
        a.push(<option value={-1}>- - -</option>);
        areas.map((item, index) => {
            if (selectedChannel > -1 && typeof channels[selectedChannel] !== 'undefined' && channels[selectedChannel].ID !== item.IDChannel) return;
            a.push(<option value={index}>{item.Name}</option>);
        });
        return a;
    }

    const updateRows = () => {

        loadSchedules();

    }

    const checkPlayback = (params) => {

        const filename = params.row.playPath;

        const extension = filename.split(".").reverse()[0];
        let mimetype = "audio";
        if (extension === 'mp4' || extension === 'avi' || extension === 'mpeg' || extension === 'mpg') mimetype = 'video';
        if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') mimetype = 'image';

        if (filename === '' || Number(params.row.wasFound) === 0) return false;
        const playpath = 'api/getAudioData.php?filename=' + filename + "&refresh_date=" + new Date().getTime();
        setMimeType(mimetype);
        setPlayPath(playpath);

    }

    const closePlayer = () => {

        setPlayPath("");
        setMimeType("");

    }

    const renderPlayer = () => {

        //console.log(playingPath);
        if (mimeType === 'video')
            return (

                <div className='app-videoplayer-fixed-cnt'>
                    <div className='app-videoplayer-close-btn' onClick={() => closePlayer()}>X</div>
                    <video controls src={playingPath} autoPlay={true} className='app-videoplayer-object' controlsList="nodownload" />
                </div>

            );

        if (mimeType === 'audio')
            return (

                <div className='app-player-fixed-cnt'>
                    <div className='app-videoplayer-close-btn' onClick={() => closePlayer()}>X</div>
                    <audio className='app-audio-ctrl' controls src={playingPath} autoPlay={true} controlsList="nodownload" />
                </div>

            );

    }

    let today = new Date();

    return (

        <div className='app-tablecnt-wrapper'>
            {loading ? <Loading /> : ''}
            <div className='app-table-filter-cnt'>
                <div className='app-table-title' style={{ width: '300px' }}>
                    Orari
                    {parseSearch() !== '' ? <div className='app-table-subtitle'>ID Programmazione {parseSearch()}</div> : ''}
                </div>

                <div className='app-table-filter-item-cnt-small'>
                    <div className='app-table-filter-label'>Inizio P.</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            InputProps={{
                                disableUnderline: true,
                            }}
                            value={date}
                            onChange={setDate}
                            format={'dd/MM/yyyy'}
                            minDate={new Date().setDate(new Date().getDate() - 15)}
                            maxDate={dateTo}
                            disablePast={false}
                            className='app-table-filter-input'
                        />
                    </MuiPickersUtilsProvider>
                </div>

                <div className='app-table-filter-item-cnt-small'>
                    <div className='app-table-filter-label'>Fine P.</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            InputProps={{
                                disableUnderline: true,
                            }}
                            value={dateTo}
                            onChange={setDateTo}
                            format={'dd/MM/yyyy'}
                            minDate={date}
                            maxDate={new Date().setDate(new Date().getDate() + 15)}
                            disablePast={false}
                            className='app-table-filter-input'
                        />
                    </MuiPickersUtilsProvider>
                </div>

                <div className='app-table-filter-item-cnt'>
                    <div className='app-table-filter-label'>Canale</div>
                    <select value={selectedChannel} onChange={(e) => setSelectedChannel(e.target.value)} className='app-table-filter-input'>{renderChannels()}</select>
                </div>
                <div className='app-table-filter-item-cnt'>
                    <div className='app-table-filter-label'>Area</div>
                    <select value={selectedArea} onChange={(e) => setSelectedArea(e.target.value)} className='app-table-filter-input'>{renderAreas()}</select>
                </div>
                <div className='app-table-filter-item-cnt'>
                    <button className='app-refresh-table-btn' onClick={() => updateRows()} >Aggiorna Tabella</button>
                </div>
            </div>
            {playingPath !== '' ? renderPlayer() : ''}
            <TableElement columns={columns} id_table='playlist' rows={rows} checkPlayback={checkPlayback} />


        </div>

    );



}

export default ContractList;