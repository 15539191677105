import React from 'react';
import { useHistory } from 'react-router-dom'

function TopBar({ moveHistory, handleLogout, username, topbarImage }) {

    let history = useHistory();
    const { pathname } = history.location;

    return (<div className='app-topbar-cnt'>

        <div className='app-topbar-logo-cnt'>
            <div className='app-topbar-logospot' style={{ backgroundImage: 'url("' + topbarImage + '")' }} />
        </div>
        <div onClick={() => moveHistory('/contracts')} className='app-topbar-item' style={pathname === '/contracts' ? { backgroundColor: '#36f7d7', color: 'black' } : {}}>Contratti</div>
        <div onClick={() => moveHistory('/schedules')} className='app-topbar-item' style={pathname === '/schedules' ? { backgroundColor: '#36f7d7', color: 'black' } : {}}>Programmazioni</div>
        <div onClick={() => moveHistory('/playlist')} className='app-topbar-item' style={pathname === '/playlist' ? { backgroundColor: '#36f7d7', color: 'black' } : {}}>Orari</div>
        <div onClick={() => moveHistory('/materials')} className='app-topbar-item' style={pathname === '/materials' ? { backgroundColor: '#36f7d7', color: 'black' } : {}}>Materiali</div>

        <div className='app-topbar-customer-item'>
            <div className='app-topbar-username-cnt'>
                <div className='app-username-icon-cnt app-user-icon' />
                <div className='app-username-actual'>{username}</div>
            </div>
            <div className='app-topbar-logout' onClick={() => handleLogout()}>
                <div className='app-logout-icon-cnt app-logout-icon' />
            </div>
        </div>

    </div >);

}

export default TopBar;