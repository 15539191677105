import React, { useEffect, useState } from 'react';
import TableElement from './TableElement';
import Loading from './Loading';

import axios from 'axios';

function Materials() {

    const [rows, setRows] = useState([]);
    const [channels, setChannels] = useState([]);
    const [areas, setAreas] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedChannel, setSelectedChannel] = useState(-1);
    const [selectedArea, setSelectedArea] = useState(-1);
    const [playingPath, setPlayPath] = useState("");
    const [mimeType, setMimeType] = useState("audio");



    useEffect(() => {

        loadMaterials();

        axios.get('api/app.php?getChannels').then(x => {

            setChannels(x.data);

        }).catch(error => { });

        axios.get('api/app.php?getAreas').then(x => {

            setAreas(x.data);

        }).catch(error => { });


    }, []);

    const loadMaterials = () => {

        const channelQ = selectedChannel === -1 || typeof channels[selectedChannel] === 'undefined' ? '' : ("&channel=" + channels[selectedChannel].ID);
        const areaQ = selectedArea === -1 || typeof areas[selectedArea] === 'undefined' ? '' : ("&area=" + areas[selectedArea].ID);
        setRows([]);
        setLoading(true);

        axios.get('api/app.php?getMaterials' + channelQ.toString() + areaQ.toString()).then(x => {

            setLoading(false);
            if (typeof x.data !== 'object') return false;
            setRows(x.data);

        }).catch(error => { });

    }

    useEffect(() => {

        setSelectedArea(-1);

    }, [selectedChannel])

    const width_ = window.innerWidth;

    const [columns, setColumns] = useState([
        {
            field: 'Code',
            headerName: 'Codice',
            width: (width_ / 100) * 10,
        },
        {
            field: 'ValidTo',
            headerName: 'Fine Validità',
            width: (width_ / 100) * 10,
        },
        {
            field: 'Subject',
            headerName: 'Titolo',
            width: (width_ / 100) * 35,
        },
        {
            field: 'Cname',
            headerName: 'Cliente',
            width: (width_ / 100) * 15,
        },
        {
            field: 'Filename',
            headerName: '',
            width: (width_ / 100) * 2,
            renderCell: (params) => {
                if (params.row.filename !== '' && params.row.wasFound == 1)
                    return (
                        <div onClick={() => checkPlayback(params)} className="app-playback-material" style={{ cursor: "pointer" }}>
                            <div className='app-username-icon-cnt app-play-icon' />
                        </div>
                    ); else {
                    return <div />
                }
            }
        },
        {
            field: 'Length',
            headerName: 'Durata',
            width: (width_ / 100) * 5,
            align: 'right'
        },
        {
            field: 'Category',
            headerName: 'Categoria',
            width: (width_ / 100) * 12,
        },
        {
            field: 'CreationDate',
            headerName: 'Data Creazione',
            width: (width_ / 100) * 10,
        },

    ]);

    const renderChannels = () => {
        let c = [];
        c.push(<option value={-1}>- - -</option>);
        channels.map((item, index) => {
            c.push(<option value={index}>{item.Name}</option>);
        });
        return c;
    }

    const renderAreas = () => {
        let a = [];
        a.push(<option value={-1}>- - -</option>);
        areas.map((item, index) => {
            if (selectedChannel > -1 && channels[selectedChannel].ID !== item.IDChannel) return;
            a.push(<option value={index}>{item.Name}</option>);
        });
        return a;
    }

    const checkPlayback = (params) => {

        const filename = params.row.playPath;

        const extension = filename.split(".").reverse()[0];
        let mimetype = "audio";
        if (extension === 'mp4' || extension === 'avi' || extension === 'mpeg' || extension === 'mpg') mimetype = 'video';
        if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') mimetype = 'image';

        if (filename === '' || Number(params.row.wasFound) === 0) return false;
        const playpath = 'api/getAudioData.php?filename=' + filename + "&refresh_date=" + new Date().getTime();
        setMimeType(mimetype);
        setPlayPath(playpath);

    }

    const closePlayer = () => {

        setPlayPath("");
        setMimeType("");

    }

    const renderPlayer = () => {

        //console.log(playingPath);
        if (mimeType === 'video')
            return (

                <div className='app-videoplayer-fixed-cnt'>
                    <div className='app-videoplayer-close-btn' onClick={() => closePlayer()}>X</div>
                    <video controls src={playingPath} autoPlay={true} className='app-videoplayer-object' controlsList="nodownload" />
                </div>

            );

        if (mimeType === 'audio')
            return (

                <div className='app-player-fixed-cnt'>
                    <div className='app-videoplayer-close-btn' onClick={() => closePlayer()}>X</div>
                    <audio className='app-audio-ctrl' controls src={playingPath} autoPlay={true} controlsList="nodownload" />
                </div>

            );

    }

    const updateRows = () => {

        loadMaterials();

    }

    return (

        <div className='app-tablecnt-wrapper'>
            {loading ? <Loading /> : ''}
            <div className='app-table-filter-cnt'>
                <div className='app-table-title'>Materiali</div>
                <div className='app-table-filter-item-cnt'>
                    <div className='app-table-filter-label'>Canale</div>
                    <select value={selectedChannel} onChange={(e) => setSelectedChannel(e.target.value)} className='app-table-filter-input'>{renderChannels()}</select>
                </div>
                <div className='app-table-filter-item-cnt'>
                    <div className='app-table-filter-label'>Area</div>
                    <select value={selectedArea} onChange={(e) => setSelectedArea(e.target.value)} className='app-table-filter-input'>{renderAreas()}</select>
                </div>
                <div className='app-table-filter-item-cnt'>
                    <button className='app-refresh-table-btn' onClick={() => updateRows()} >Aggiorna Tabella</button>
                </div>
            </div>
            {playingPath !== '' ? renderPlayer() : ''}
            <TableElement columns={columns} id_table='schedules' rows={rows} />


        </div>

    );



}

export default Materials;